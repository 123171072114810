import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DirectoryService,SearchOptions } from '../shared/directory/directory.service';
import { User } from '../shared/directory/user.model';
import { ActivatedRoute } from '@angular/router';
import { Observable, throwError,of } from 'rxjs';

@Component({
  selector: 'app-directoryview',
  templateUrl: './directoryview.component.html',
  styleUrls: ['./directoryview.component.scss']
})
export class DirectoryviewComponent implements OnInit {

  public listUsers : User[];
  public pageNumber : number = 1;
  public pageSize : number = 15;
  public searchString : string = "";
  public searched : boolean = false;
  public totalItems : number = 0;
  public loading: boolean = false;
  public error: boolean = false;
  public errorObj : any;
  public lisatiotojaIsCollapsed = false;

  constructor(private directory: DirectoryService,private route: ActivatedRoute ) { 

    this.listUsers = [];
    
  }
  

  ngOnInit(): void {

  }

  pageChanged($event){

    this.pageNumber = $event.page;
    this.searchUsers();

  }

  searchUsers() : void{

    var options: SearchOptions  = {searchString: this.searchString, pageSize:this.pageSize, pageNumber: this.pageNumber-1};
    this.loading = true;
    this.error = false;
    this.directory.searchUsers(options).subscribe((res:any) => {
        this.searched = true;
        this.listUsers = res.data.users;
        this.totalItems = res.data.totalCount;
        this.loading = false;
        this.error = false;
       // document.getElementById("pagination-ctrl").scrollIntoView();
       },
       err => {
        console.log(err);
         this.searched = true;
        this.error = true;
        this.errorObj = err;
        this.loading = false;
      
       }
       );

  }

   onSubmit(f: NgForm) {

    this.searchUsers();

   }

}
