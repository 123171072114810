

  
  <div class="row justify-content-md-center blaah haku-container">

    <div class="col">
      <h1 class="text-center mt-3 mb-3">Puhelinnumeroiden ja yritystietojen hakupalvelu</h1>

      <form #searchForm="ngForm" (ngSubmit)="onSubmit(searchForm)">
        <div class="form-group">
          <!--<label for="searchInput">Etsi henkilöä:</label>-->
          <!-- yksi kirjain / numero ([A-Za-z0-9äöüåßÄÖÜÅ]) vähintään ja N merkkiä [A-Za-z0-9äöüåßÄÖÜÅ\- \*&\x22]  -->
          <input type="search" [(ngModel)]="searchString"  #searchStringInput="ngModel"  name="searchStringInput" class="form-control" id="searchStringInput" aria-describedby="searchInput" placeholder="Etsi henkilöä" 
          minlength="3"

          pattern="[A-Za-z0-9äöüåßÄÖÜÅ\- \*&\x22]*[A-Za-z0-9äöüåßÄÖÜÅ]+[A-Za-z0-9äöüåßÄÖÜÅ\- \*&\x22]*"
          autofocus
          required
          >
          <div *ngIf="searchStringInput.invalid">
           <!-- <p *ngIf="searchStringInput.errors.minlength">Minimi hakusanan pituus 3 merkkiä</p>-->
            <p style="font-size:0.8em;" *ngIf="searchStringInput.errors.pattern">*Vain aakkosnumeeriset merkit sallittuja hakusanassa sekä merkit *,-,& ja välilyönti.</p>

          </div>
         <!-- <small id="searchInputHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>-->
        </div>

        <!--<div *ngIf="name.invalid && (name.dirty || name.touched)"
          class="alert alert-danger">

        <div *ngIf="name.errors.required">
          Name is required.
        </div>
        <div *ngIf="name.errors.minlength">
          Name must be at least 4 characters long.
        </div>
        <div *ngIf="name.errors.forbiddenName">
          Name cannot be Bob.
        </div>

      </div>-->
        <!--<h1>valid: {{searchString.invalid}}</h1>-->
       <!-- {{searchForm.valid | json}}-->
       <div class="row ">
        <div class="col"></div>

        <div class="col-xl-2 col-lg-2 col-md-6  col-xs-12" >
          <!-- [disabled]="searchString.invalid || !searchString.touched || -->
         <button type="submit"  [disabled]="loading || searchString.length < 3 || searchForm.invalid" class="btn btn-primary w-100 mb-2"><span *ngIf="!loading">Hae</span> <div *ngIf="loading" class="spinner-border" role="status"></div></button>
        </div>

        <div class="col"></div>
        </div>

       <!-- <a class="" (click)="lisatiotojaIsCollapsed = !lisatiotojaIsCollapsed" [attr.aria-expanded]="!lisatiotojaIsCollapsed" aria-controls="collapseBasic">
          Lisätietoa <i *ngIf="!lisatiotojaIsCollapsed" class="fa fa-arrow-right"></i><i *ngIf="lisatiotojaIsCollapsed" class="fa fa-arrow-down"></i>
 
        </a>  -->
       
      <!-- <div class="mt-2 info-list" id="collapseBasic" [collapse]="lisatiotojaIsCollapsed">
         <ul>
          <li>Voit hakea henkilöitä esim. nimellä, osoitteella, nimikkeellä, puhelinnumerolla, postinumerolla </li><li> Hakulausekkeen tulee olla vähintään 3 merkkiä.</li><li>Puhelinnumeron tulee olla kansainvälisessä muodossa ilman plus-merkkiä</li><li>Puhelinvaihteen tavoittaa soittamalla numeroon 03 828 11 (tai Salpauksen matkapuhelimesta 889).</li>
          <li>Palvelu ei tallenna käyttäjistä henkilökohtaisia tietoja eikä käytä evästeitä</li>
         </ul>
        </div>-->
     
     <div class="mt-2 info-list 	d-none d-md-block" id="collapseBasic" [collapse]="lisatiotojaIsCollapsed">
         <ul>
          <li>Voit hakea henkilöitä esim. nimellä, nimikkeellä, yksiköllä, puhelinnumerolla, osoitteella tai postinumerolla.</li>
          <li>Kirjoita hakuun vähintään 3 merkkiä.</li>
          <li>Lisää sanan tai lauseen ympärille lainausmerkit, jos haluat tarkan tuloksen, esim. "Svinhufvudinkatu 2".</li>
          <li>Puhelinnumeron tulee olla kansainvälisessä muodossa ilman plus-merkkiä.</li>
          <li>Tavoitat puhelinvaihteemme numerosta <a href="tel:+358382811"> 03 828 11*</a> (tai Salpauksen matkapuhelimesta 889).</li>
          <li>Palvelu ei tallenna käyttäjistä henkilökohtaisia tietoja eikä käytä evästeitä.</li>
          
          <li>Maksuton puhelinnumeron ja yritystietojen hakupalvelu</li>
          <ul>
            <li>Puhelinnumeroita ja yritysten yhteystietoja saa ilmaiseksi haettua selaimella toimivasta Fonectan Finder.fi ja puhelimeen ladattavasta Caller.fi palveluista</li>
            <li>Maksullisia numerotiedustelupalveluita, esim. 020202 ei suositella käytettäväksi.</li>
          </ul>
        </ul>
        </div>

    </form>



    </div>
    
   

  </div>

  <div class="row pagination-container" *ngIf="searched" id="pagination-ctrl">
     <!-- <pagination [totalItems]="30"></pagination>-->

     <pagination class="ml-1"[totalItems]="totalItems" [maxSize]="7" [itemsPerPage]="pageSize" [(ngModel)]="pageNumber" (pageChanged)="pageChanged($event)" previousText="&lsaquo;" nextText="&rsaquo;"></pagination>
  
     <p class="mb-0 ml-auto mr-1 mt-auto push-right results-count" > Näytetään tulokset {{(pageNumber-1)*pageSize + 1}}-{{(pageNumber-1)*pageSize + listUsers.length}}/{{totalItems}}</p>
  </div>


  <div class="row justify-content-md-center blaah result-container mt-1 pb-1" *ngIf="searched && !error">

    <div class="col ml-0 mr-0 pl-2 pr-0 list-user-container">
    <!-- <ul style="list-style: none; margin:0; padding: 0" *ngIf="listUsers.length > 0">
      <li *ngFor="let user of listUsers">
          <app-listuser [user]="user"></app-listuser>         
      </li>
      </ul>-->
      <div *ngIf="loading" class="mt-5 mb-5">
       <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      </div>
      
      <ng-container *ngIf="!loading">
      <ng-container *ngFor="let user of listUsers">
          <app-listuser [user]="user"></app-listuser>         
      </ng-container>
       </ng-container>

      <p class="ml-1" *ngIf="listUsers.length == 0 && searched && !loading">Haku ehdolla ei löytynyt henkilöitä!</p>

      
    </div>


  </div>



    <div class="row justify-content-md-center blaah result-container mt-1 pb-1" *ngIf="searched && error">

    <div class="col ml-0 mr-0 pl-0 pr-0 list-user-container">
    <!-- <ul style="list-style: none; margin:0; padding: 0" *ngIf="listUsers.length > 0">
      <li *ngFor="let user of listUsers">
          <app-listuser [user]="user"></app-listuser>         
      </li>
      </ul>-->
      <div *ngIf="loading" class="mt-5 mb-5">
       <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      </div>
      
      <ng-container *ngIf="!loading">
      <ng-container *ngFor="let user of listUsers">
          <app-listuser [user]="user"></app-listuser>         
      </ng-container>
       </ng-container>

      <div *ngIf="listUsers.length == 0 && searched" class="alert alert-info mt-1 mb-1 ml-1 mr-1" role="alert">Yhteys puhelinluetteloon epäonnistui, kokeile myöhemmin uudestaan!</div>

      
    </div>
  </div>

  <div class="row pagination-container mt-1 mb-2" *ngIf="searched && listUsers.length >= 3" id="pagination-ctrl2" >
     <!-- <pagination [totalItems]="30"></pagination>-->

     <pagination class="ml-1"[totalItems]="totalItems" [maxSize]="7" [itemsPerPage]="pageSize" [(ngModel)]="pageNumber" (pageChanged)="pageChanged($event)" previousText="&lsaquo;" nextText="&rsaquo;"></pagination>
  
    <!-- <p class="mb-0 ml-auto mr-1 mt-auto push-right results-count" > Näytetään tulokset {{(pageNumber-1)*pageSize + 1}}-{{(pageNumber-1)*pageSize + listUsers.length}}/{{totalItems}}</p>-->
  </div>



  


