import { TopnavComponent } from './topnav/topnav.component';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { DirectoryService } from './directory/directory.service';
import { CommonModule } from '@angular/common';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
@NgModule({
  imports: [CommonModule,HttpClientModule,PaginationModule.forRoot(),CollapseModule.forRoot(),ModalModule.forRoot(),BrowserAnimationsModule],
  declarations: [TopnavComponent],
  entryComponents: [],
  exports: [TopnavComponent,CommonModule]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers:[
        DirectoryService
      ]
      /*,
      providers: [
      AlertService,
      UserService,
      UserModalService,
      RoleService,
      AuthenticationGuard,
      AuthorizationGuard,
      AuthenticationService,
      AuthorizationService,
      HotspotContentService,
      HotspotContentModalService,
      ConfirmModalService,
      ShareService,
      ShareModalService,
      PublicLinkModalService,
      PublicLinkService,
      LanguageService,
      FolderService,
      FolderModalService,
      FileService,
      FileModalService,
      QuestionModalService,  
      DragulaService,
      WysiwygModalService,
      MediaElementModalService,
      {
        provide: JwtHttp,
        useFactory: jwtHttpFactory,
        deps: [ Http ]
      } 
      ]*/
    };
  }
}


