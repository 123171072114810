
    <div class="col-lg-6 mt-1 list-user" >
        <div class="d-flex flex-row border rounded">
            <div class="p-0 w-20 thumb-container">
                <!--<img src="https://c1.staticflickr.com/3/2862/12328317524_18e52b5972_k.jpg" class="img-thumbnail border-0 my-thumb" />-->
                <img *ngIf="!user.photo" src="/assets/profile-42914__3402.png" class="img-thumbnail border-0" />
                <img *ngIf="user.photo" src="data:image/jpeg;base64,{{user.photo}}" class="img-thumbnail border-0" />

            </div>
            <div class="pl-3 pt-2 pr-2 pb-2 w-85 border-left">
               
                    <h4 *ngIf="!user.givenName" class="text-primary">{{user.fullName}}</h4>
                    <h4 *ngIf="user.givenName" class="text-primary">{{user.sn}} {{user.givenName}}</h4>
                    <h5 class="text-info"> 
                       <span *ngIf="user.title">{{user.title}} </span>  <span *ngIf="!user.title">titteli  </span>
                       <br>
                       <span *ngIf="user.department">{{user.department}} </span> <span *ngIf="!user.title">osasto  </span>
                        <!-- <span *ngIf="user.company">/ {{user.company}} </span> <span *ngIf="!user.title"> yritys  </span>-->
                    </h5>
                    <ul class="m-0 float-left user-links" style="list-style: none; margin:0; padding: 0">
                        <li><i class="fa fa-phone-square fa-fw"  title="puhelinnumero" ></i> <a href="tel:{{user.mobile}}"> {{user.mobile}} </a> </li>                       
                        <li><i class="fa fa-envelope-square fa-fw" title="sähköposti"></i> <a href="mailto:{{user.email}}"> {{user.email |lowercase}}</a></li>
                         <li><i class="fa fa-map-marker fa-fw" title="osoite"></i> <a  target="_blank" href="https://maps.google.com/?q={{user.address}}+{{user.city}}"> {{user.address}}</a><span *ngIf="user.postalcode">, {{user.postalcode}}</span> <span *ngIf="user.city" >, {{user.city}}</span></li>
                    </ul>
                    <!--<p class="text-right m-0"><a href="#" class="btn btn-primary"><i class="far fa-user"></i> View Profile</a></p>-->
            </div>
        </div>
    </div>