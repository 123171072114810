<div class="container pl-0 pr-0">

<a >
  <!--<h1>My Store</h1>-->
  <img src="/assets/salpaus-logo-tekstioikealla-rgb-oranssi300.png" class="banner "/>
</a>

<!--<h2>Puhelinluettelo</h2>-->
<!--<a  class="button fancy-button"><i class="material-icons">shopping_cart</i>Checkout</a>-->
<button type="button" class="btn btn-primary pull-right 	 d-md-none" title="Apua/lisätietoja" (click)="openModal(template)"><i class="fa fa-info-circle"></i></button> 
  <ng-template #template>
  <div class="modal-header">
      <h4 class="modal-title pull-left">Lisätietoa</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="mt-2 info-list">
           <ul>
          <li>Voit hakea henkilöitä esim. nimellä, nimikkeellä, yksiköllä, puhelinnumerolla, osoitteella tai postinumerolla.</li>
          <li>Kirjoita hakuun vähintään 3 merkkiä.</li>
          <li>Lisää sanan tai lauseen ympärille lainausmerkit, jos haluat tarkan tuloksen, esim. "Svinhufvudinkatu 2".</li>
          <li>Puhelinnumeron tulee olla kansainvälisessä muodossa ilman plus-merkkiä.</li>
          <li>Tavoitat puhelinvaihteemme numerosta <a href="tel:+358382811"> 03 828 11*</a> (tai Salpauksen matkapuhelimesta 889).</li>
          <li>Palvelu ei tallenna käyttäjistä henkilökohtaisia tietoja eikä käytä evästeitä.</li>
          <li>Maksuton puhelinnumeron ja yritystietojen hakupalvelu</li>
          <ul>
            <li>Puhelinnumeroita ja yritysten yhteystietoja saa ilmaiseksi haettua selaimella toimivasta Fonectan Finder.fi ja puhelimeen ladattavasta Caller.fi palveluista</li>
            <li>Maksullisia numerotiedustelupalveluita, esim. 020202 ei suositella käytettäväksi.</li>
          </ul>
         </ul>
        </div>
    </div>
  </ng-template>

  </div>
    