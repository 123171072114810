import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { User } from './user.model';

import { Observable, throwError,of } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
 

 
import { WebConfig as webConfig} from '../config/default'




/**
 * This class provides method for users api usege
 */

export class SearchOptions {

    searchString?: string;
    pageSize?: number;
    pageNumber?: number;

}

@Injectable()
export class DirectoryService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
   // private options = new RequestOptions({ headers: this.headers });
    private options = { headers: this.headers };
    private apiBaseUrl = webConfig.apiBaseUrl;
    private routeUrl = 'user';
    private url = '';

    constructor(
        private http: HttpClient) {

            this.url = this.apiBaseUrl + '/' + this.routeUrl;
    }
 

    searchUsers(searchOptions:SearchOptions): Observable<User[]> {
    
       // const myObservable = of([{name:"test1"}, {name:"test2"}, {name:"test3"}]);
        //return myObservable;
        //options = JSON.stringify(options);
        // get users from api
        console.log("SearchOptions %O", searchOptions);
        return this.http.post<User[]>(this.url + '/search',searchOptions,this.options)
        //.map(res => res.json())
        .pipe(
            
        retry(1),
        catchError(this.errorHandl)
        );


            
    }


   
   private errorHandl(error) {
     let errorMessage = '';
     if(error.error instanceof ErrorEvent) {
       // Get client-side error
       errorMessage = error.error.message;
     } else {
       // Get server-side error
       errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
     }
     console.log(errorMessage);
     return throwError(errorMessage);
  }

}