import { DirectoryviewComponent } from './directoryview.component';
import { ListuserComponent } from './listuser/listuser.component';

import { NgModule, ModuleWithProviders } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { FormsModule }   from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CollapseModule } from 'ngx-bootstrap/collapse';


@NgModule({
  imports: [SharedModule,FormsModule,PaginationModule,CollapseModule],
  declarations: [DirectoryviewComponent,ListuserComponent],
  entryComponents: [],
  exports: [DirectoryviewComponent,ListuserComponent]
})

export class DirectoryviewModule {
 
}


