import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-listuser',
  templateUrl: './listuser.component.html',
  styleUrls: ['./listuser.component.scss']
})
export class ListuserComponent implements OnInit {
  
  @Input() user: any;


  constructor() { }

  ngOnInit(): void {
  }

}
